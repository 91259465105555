import {observer} from "mobx-react-lite";
import OrderStore from "../stores/OrderStore";
import {useEffect} from "react";
import {Space} from "@nutui/nutui-react";
import UserStore from "../stores/UserStore";

function Order() {
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;


  useEffect(() => {
    let uri = ""
    if (localStorage.getItem("table") === "0") {
      uri = process.env.REACT_APP_API_HOST + "/api/order/unpaid/id/" + UserStore.userInfo.id;
    } else {
      uri = process.env.REACT_APP_API_HOST + "/api/order/unpaid/table/" + localStorage.getItem("table");
    }
    OrderStore.setAllOrderItems(uri)
  }, [])

  return (
    <>
      <div style={{height: windowHeight - 70, padding: '20px 20px 0 20px'}}>
        {/*页头*/}
        <div style={{width: windowWidth - 40}}>
          <div style={{fontSize: '26px', fontWeight: 'bold'}}>Información del pedido</div>
          {localStorage.getItem("table") === "0" ?
            <div style={{margin: '7px 0 10px 0'}} className="f16">Delivery</div> :
            <div style={{margin: '7px 0 10px 0'}} className="f16">Mesa {localStorage.getItem("table")}</div>
          }
        </div>
        {/*订单列表*/}
        <div style={{height: windowHeight - 138, overflow: 'auto'}}>
          {OrderStore.allOrderItems.length > 0 ?
            <div>
              {
                OrderStore.allOrderItems.map((order) => (
                  <div
                    key={order.id}
                    className="order_item_container"
                  >
                    {/*主内容*/}
                    <div style={{marginBottom: '10px'}}>
                      {order.goodsRsp.map((good, index) => (
                        <div key={index}>
                          <div className="flex_between">
                            <div className="f14">{good.name}</div>
                            <div className="dotted_line"></div>
                            <Space>
                              <div className="f12">€{good.price}</div>
                              <div className="f12">x{good.num}</div>
                            </Space>
                          </div>
                          <div className="f12" style={{color: "var(--nutui-gray-5)"}}>{good.chineseName}</div>
                        </div>
                      ))}
                    </div>
                    <div
                      className="f12 margin_bottom_6">{order.preTime.length > 0 && "Tiempo reservado: " + order.preTime}</div>
                    {/*备注行*/}
                    <div className="f12 margin_bottom_6">{order.note.length > 0 && "Nota: " + order.note}</div>
                    {/*最后一行*/}
                    <Space>
                      <div className="f12 margin_bottom_6">{order.method}</div>
                      <div className="f12">{order.isDeliver ? "Delivery" : "Recogida"}</div>
                    </Space>
                    <div className="flex_between">
                      <div className="f12"
                           style={{color: order.status === "No pagado" ? 'var(--nutui-color-primary)' : 'orange'}}>{order.status}</div>
                      <Space>
                        <div className="f12">{order.time}</div>
                        <div className="f12">€{(order.totalPrice).toFixed(2)}</div>
                      </Space>
                    </div>
                  </div>
                ))
              }
            </div>
            :
            <div className="no_data" style={{width: windowWidth - 40, height: windowHeight - 143}}>
              No hay datos...
            </div>
          }
        </div>
      </div>
    </>
  )
}

export default observer(Order);
