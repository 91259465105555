import {makeAutoObservable, runInAction} from "mobx";
import axios from "axios";

class UserStore {
  isLogin = false;
  userInfo = {}

  constructor() {
    makeAutoObservable(this);
    this.loadToken();
  }

  loadToken() {
    const token = localStorage.getItem('token')
    if (token) {
      this.isLogin = true;
      this.setUserInfo();
    }
  }

  login(token) {
    localStorage.setItem("token", token)
    this.isLogin = true;
    this.setUserInfo();
  }


  setUserInfo = () => {
    const uri = process.env.REACT_APP_API_HOST + "/api/user/get/info"
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token")
      }
    }
    axios.get(uri, headers).then(
      (rsp) => {
        runInAction(
          () => {
            if (rsp.data.status === "success") {
              this.userInfo = rsp.data.data
            }
          }
        )
      }
    ).catch(
      (error) => {
        console.log(error)
      }
    )
  }

}

const userStore = new UserStore();
export default userStore;
