import {makeAutoObservable, runInAction} from "mobx";
import axios from "axios";
import {Toast} from "@nutui/nutui-react";

class OrderStore {

  inCartGoodsItems = []
  allOrderItems = []

  constructor() {
    makeAutoObservable(this)
  }

  setInCartGoodsItems = (goods, n) => {
    const existingGoodsItem = this.inCartGoodsItems.find(item => item.goods.id === goods.id)
    if (existingGoodsItem) {
      if (n === 0) {
        this.inCartGoodsItems = this.inCartGoodsItems.filter(item => item.goods.id !== goods.id)
      } else {
        existingGoodsItem.num = n;
      }
    } else {
      if (n !== 0) {
        this.inCartGoodsItems.push({goods: goods, num: n})
      }
    }
  }

  clearInCartGoodsItems = () => {
    this.inCartGoodsItems = [];
  }

  deleteInCartGoodsItems = (id) => {
    this.inCartGoodsItems = this.inCartGoodsItems.filter(item => item.goods.id !== id);
  }

  setAllOrderItems = (uri) => {
    axios.get(uri).then(
      (rsp) => {
        runInAction(
          () => {
            if (rsp.data.status === "success") {
              this.allOrderItems = rsp.data.data
            }
            if (rsp.data.status === "error") {
              Toast.show({
                content: rsp.data.message
              })
            }
          })
      }
    ).catch(error => console.log(error))
  }
}

const orderStore = new OrderStore();
export default orderStore;
