import './App.css';
import "./icon/iconfont.css"
import '@nutui/nutui-react/dist/style.css'
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Menu from "./pages/Menu";
import Cart from "./pages/Cart";
import Order from "./pages/Order";
import Personal from "./pages/Personal";
import Main from "./pages/Main";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main/>}>
          <Route path='menu' element={<Menu/>}/>
          <Route path='cart' element={<Cart/>}/>
          <Route path='order' element={<Order/>}/>
          <Route path='personal' element={<Personal/>}/>
        </Route>
        <Route path="*" element={<Navigate to="/"/>}/>
      </Routes>
    </BrowserRouter>
  )
}

export default App;
