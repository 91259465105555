import {Swiper, Toast, Image, Tabs, InputNumber, Popup, Space, Tag, NoticeBar} from "@nutui/nutui-react";
import {useEffect, useState} from "react";
import axios from "axios";
import OrderStore from "../stores/OrderStore";
import {observer} from "mobx-react-lite";

function Menu() {
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const goodsListContainerHeight = windowHeight - (windowWidth - 40) / 2.8125 - 230;

  const [bannerTime, setBannerTime] = useState(4000);

  const [bannerList, setBannerList] = useState([]);
  const [goodsList, setGoodsList] = useState([]);

  const [selectedTabValue, setSelectedTabValue] = useState("");
  const [showGoodsDetailPop, setShowGoodsDetailPop] = useState(false)
  const [showGoodsDetailValue, setShowGoodsDetailValue] = useState({})

  const [notice, setNotice] = useState(null);

  // 获取notice
  const getNotice = () => {
    const uri = process.env.REACT_APP_API_HOST + "/api/setting/notice/get";
    axios.get(uri).then(rsp => {
      if (rsp.data.status === "success") {
        setNotice(rsp.data.data);
      }
    }).catch(error => console.log(error));
  }

  // 获取所有banner
  const getBanners = () => {
    const uri = process.env.REACT_APP_API_HOST + "/api/goods/banner/list/all";
    axios.get(uri).then(rsp => {
      if (rsp.data.status === "success") {
        setBannerList(rsp.data.data);
      }
      if (rsp.data.status === "error") {
        Toast.show({
          content: rsp.data.message
        })
      }
    }).catch(error => console.log(error));
  }

  // 获取所有goods
  const getGoods = () => {
    const uri = process.env.REACT_APP_API_HOST + "/api/goods/list/all";
    axios.get(uri).then(rsp => {
      if (rsp.data.status === "success") {
        //重构数据
        const categoryWithGoodsList = Object.values(rsp.data.data.reduce((acc, item) => {
          if (!acc[item.categoryId]) {
            acc[item.categoryId] = {
              categoryId: item.categoryId,
              categoryName: item.category.name,
              iconId: item.category.iconId,
              iconEx: item.category.iconEx,
              sort: item.category.sort,
              goods: []
            };
          }
          acc[item.categoryId].goods.push({
            id: item.id,
            name: item.name,
            chineseName: item.chineseName,
            price: item.price,
            description: item.description,
            image: item.image,
            tags: item.tags,
            status: item.status,
          });
          return acc;
        }, {}));
        //排序
        categoryWithGoodsList.sort((a, b) => {
          return a.sort - b.sort;
        });
        setGoodsList(categoryWithGoodsList);
        setSelectedTabValue(categoryWithGoodsList[0].categoryId);
      }
      if (rsp.data.status === "error") {
        Toast.show({
          content: rsp.data.message
        })
      }
    }).catch(error => console.log(error));
  }

  // 根据id获取goods
  const getGoodsById = (id) => {
    const uri = process.env.REACT_APP_API_HOST + "/api/goods/list/all?id=" + id;
    axios.get(uri).then(rsp => {
      if (rsp.data.status === "success") {
        if (rsp.data.data.length > 0) {
          setShowGoodsDetailValue(rsp.data.data[0]);
          setShowGoodsDetailPop(true)
        }
      }
      if (rsp.data.status === "error") {
        Toast.show({
          content: rsp.data.message
        })
      }
    }).catch(error => console.log(error));
  }

  // 获取购物车中，指定商品的数量
  const getGoodsNumInCart = (id) => {
    const existingGoodsItem = OrderStore.inCartGoodsItems.find(item => item.goods.id === id)
    if (existingGoodsItem) {
      return existingGoodsItem.num;
    } else {
      return 0;
    }
  }

  useEffect(() => {
    getBanners();
    getGoods();
    getNotice();
  }, [])

  return (
    <>
      {/*通知*/}
      {notice == null ? null : (
        notice.status === "on" &&
        <NoticeBar
          content={notice.value}
          leftIcon={null}
          scrollable
          closeable
        />
      )}

      <div style={{height: windowHeight - 70, padding: '20px 10px 0 20px'}}>
        {/*页头*/}
        <div style={{width: windowWidth - 40}}>
          <div style={{fontSize: '26px', fontWeight: 'bold'}}>Bienvenidos a MiPanda</div>
          {localStorage.getItem("table") === "0" ?
            <div style={{margin: '7px 0 10px 0'}} className="f16">Delivery</div> :
            <div style={{margin: '7px 0 10px 0'}} className="f16">Mesa {localStorage.getItem("table")}</div>
          }
        </div>
        {/*轮播图*/}
        <div style={{marginBottom: '10px'}}>
          <Swiper
            autoPlay={bannerTime}
            loop
            effect={{name: 'focus', scale: 0.9}}
          >
            {
              bannerList.map((item) => {
                return (
                  <Swiper.Item key={item.id}>
                    <div style={{
                      width: windowWidth - 40,
                      height: (windowWidth - 40) / 2.8125,
                      position: 'relative',
                    }}>
                      <Image src={`${process.env.REACT_APP_API_HOST}/uploads/${item.id}.jpeg`}
                             onClick={() => {
                               setBannerTime(0);
                               getGoodsById(item.goodsId);
                             }}
                             radius="20px"
                      />
                      <div className="banner_badge">{item.title}</div>
                      <div className="banner_triangle"></div>
                    </div>
                  </Swiper.Item>
                )
              })
            }
          </Swiper>
        </div>
        {/*菜单*/}
        <div style={{width: windowWidth - 40}}>
          <Tabs
            value={selectedTabValue}
            title={() => {
              return goodsList.map((item) => (
                <div
                  onClick={() => setSelectedTabValue(item.categoryId)}
                  key={item.categoryId}
                  className="tab_container"
                >
                  <div
                    className={`${selectedTabValue === item.categoryId ? 'tab_icon_container_active' : 'tab_icon_container'}`}>
                    <Image
                      src={process.env.REACT_APP_API_HOST + "/uploads/" + item.iconId + "." + item.iconEx}
                      radius="50%"/>
                  </div>
                  <div className={`${selectedTabValue === item.categoryId ? 'tab_text_active' : 'tab_text'}`}>
                    {item.categoryName}
                  </div>
                </div>
              ))
            }}
          >
            {goodsList.map((item) => (
              <Tabs.TabPane
                key={item.categoryId}
                value={item.categoryId}
              >
                <div style={{
                  height: goodsListContainerHeight,
                  overflowY: "scroll",
                }}>
                  {item.goods.map((goods) => (
                    <div key={goods.id} style={{position: 'relative'}}>
                      {!goods.status && <div className="disable_mask">Está agotado</div>}
                      <div className="tab_pane_goods_container">
                        <Image
                          src={process.env.REACT_APP_API_HOST + "/uploads/" + goods.image.id + "." + goods.image.ex}
                          width="68px"
                          height="68px"
                          radius="15px"
                          onClick={() => {
                            if (goods.status) {
                              setShowGoodsDetailPop(true);
                              setShowGoodsDetailValue(goods);
                            }
                          }}
                        />
                        <div className="tab_pane_goods_text_container">
                          <div className="f14">{goods.name}</div>
                          <div className="tab_pane_goods_text_sub_container">
                            <div>
                              <div className="f12" style={{margin: "3px 0"}}>{goods.chineseName}</div>
                              <div className="f12"
                                   style={{color: 'var(--nutui-color-primary)'}}>€{goods.price.toFixed(2)}</div>
                            </div>
                            <InputNumber
                              readOnly={true}
                              max={99}
                              min={0}
                              onChange={(param) => {
                                if (goods.status) {
                                  OrderStore.setInCartGoodsItems(goods, param)
                                }
                              }}
                              value={OrderStore.inCartGoodsItems.length > 0 ? getGoodsNumInCart(goods.id) : 0}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Tabs.TabPane>
            ))}
          </Tabs>
        </div>
      </div>

      <Popup
        visible={showGoodsDetailPop}
        position="bottom"
        onClose={() => {
          setShowGoodsDetailPop(false)
          setBannerTime(4000)
        }}
      >
        {showGoodsDetailPop &&
          <>
            {!showGoodsDetailValue.status && <div className="disable_mask" style={{borderRadius: 0}}>
              Está agotado
            </div>}
            <div style={{padding: '40px'}}>
              <div className="f18">{showGoodsDetailValue.name}</div>
              <div className="f14">{showGoodsDetailValue.chineseName}</div>
              <div style={{width: windowWidth - 80, height: windowWidth - 80, margin: '20px auto'}}>
                <Image
                  src={process.env.REACT_APP_API_HOST + "/uploads/" + showGoodsDetailValue.image.id + "." + showGoodsDetailValue.image.ex}
                  radius="5%"
                />
              </div>
              <div className="f14">
                {showGoodsDetailValue.description &&
                  <div
                    style={{wordBreak: 'break-all', marginBottom: '10px'}}>{showGoodsDetailValue.description}</div>}
              </div>
              <Space>
                {showGoodsDetailValue.tags.map((tag) => (
                  <Tag type="primary" key={tag.id} style={{marginBottom: '5px'}}>{tag.name}</Tag>
                ))}
              </Space>
              <div className="goods_detail_price_container">
                <div className="f14"
                     style={{color: 'var(--nutui-color-primary)'}}>€{showGoodsDetailValue.price.toFixed(2)}</div>
                <InputNumber
                  readOnly={true}
                  max={99}
                  min={0}
                  value={OrderStore.inCartGoodsItems.length > 0 ? getGoodsNumInCart(showGoodsDetailValue.id) : 0}
                  onChange={(param) => {
                    OrderStore.setInCartGoodsItems(showGoodsDetailValue, param)
                  }}
                />
              </div>
            </div>
          </>
        }
      </Popup>
    </>
  )
}

export default observer(Menu);
