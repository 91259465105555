import {observer} from "mobx-react-lite";
import OrderStore from "../stores/OrderStore";
import {
  Button,
  Swipe,
  Image,
  InputNumber,
  Radio,
  TextArea,
  Toast,
  Popup,
  Input
} from "@nutui/nutui-react";
import {Ask, Close, IconFont, Warning} from "@nutui/icons-react";
import {useEffect, useState} from "react";
import axios from "axios";
import UserStore from "../stores/UserStore";

function Cart() {

  const packingCharges = "0.20";

  const [currentYear, setCurrentYear] = useState('');
  const [currentMonth, setCurrentMonth] = useState('');
  const [currentDay, setCurrentDay] = useState('');

  const [preHour, setPreHour] = useState('');
  const [preMinute, setPreMinute] = useState('');

  const [isCompleteInfo, setIsCompleteInfo] = useState(false);

  const [showTimeSelect, setShowTimeSelect] = useState(false);

  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  const [listContainerHeight, setListContainerHeight] = useState(0);

  const [payType, setPayType] = useState("Efectivo")
  const [deliveryType, setDeliveryType] = useState("Delivery")

  const [noteValue, setNoteValue] = useState("")

  const [showHint, setShowHint] = useState(false);

  // 获取购物车中，指定商品的数量
  const getGoodsNumInCart = (id) => {
    const existingGoodsItem = OrderStore.inCartGoodsItems.find(item => item.goods.id === id)
    if (existingGoodsItem) {
      return existingGoodsItem.num;
    } else {
      return 0;
    }
  }

  const isOpen = () => {
    // return true;
    const now = new Date(); // 获取当前时间
    const day = now.getUTCDay(); // 获取当前日期（0-6，0是周日）
    const hours = now.getUTCHours() + 2; // 获取当前小时（0-23）
    const minutes = now.getUTCMinutes(); // 获取当前分钟（0-59）

    if (day === 2) {
      return false; // 周二休息
    }

    // 检查时间范围
    const isInFirstRange = (hours > 12 || (hours === 12 && minutes >= 0)) &&
      (hours < 16 || (hours === 16 && minutes <= 30));

    const isInSecondRange = (hours > 19 || (hours === 19 && minutes >= 30)) &&
      (hours < 23 || (hours === 23 && minutes <= 30));

    return isInFirstRange || isInSecondRange;
  }

  useEffect(() => {
    const now = new Date();
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: 'Europe/Madrid', // 设置时区为西班牙
    };
    const formatter = new Intl.DateTimeFormat('es-ES', options);
    const formattedDate = formatter.formatToParts(now);

    formattedDate.forEach(({type, value}) => {
      switch (type) {
        case 'year':
          setCurrentYear(value);
          break;
        case 'month':
          setCurrentMonth(value);
          break;
        case 'day':
          setCurrentDay(value);
          break;
        default:
          break;
      }
    });

    if (localStorage.getItem("table") === "0") {
      setListContainerHeight(windowHeight - 400);
    } else {
      setListContainerHeight(windowHeight - 287);
    }
    if (UserStore.isLogin) {
      if (UserStore.userInfo.phone != null && UserStore.userInfo.address != null) {
        setIsCompleteInfo(true)
      }
    }
  }, [])

  const getTotalPrice = () => {
    // 获取菜品基础价格
    const total = OrderStore.inCartGoodsItems.reduce((total, item) => {
      return total + item.goods.price * item.num
    }, 0)

    if (localStorage.getItem("table") === "0" && UserStore.isLogin) {
      if (deliveryType === "Delivery") {
        if (total < 15) {
          return {
            total: (total + Number(packingCharges) + Number(UserStore.userInfo.freight)).toFixed(2),
            freight: UserStore.userInfo.freight
          };
        }
        if (total >= 15 && total < 30) {
          if (UserStore.userInfo.distance >= 3000) {
            return {
              total: (total + Number(packingCharges) + Number(UserStore.userInfo.freight)).toFixed(2),
              freight: UserStore.userInfo.freight
            };
          } else {
            return {total: (total + Number(packingCharges)).toFixed(2), freight: "0.00"}
          }
        }
        if (total >= 30) {
          if (UserStore.userInfo.distance >= 5000) {
            return {
              total: (total + Number(packingCharges) + Number(UserStore.userInfo.freight)).toFixed(2),
              freight: UserStore.userInfo.freight
            };
          } else {
            return {total: (total + Number(packingCharges)).toFixed(2), freight: "0.00"}
          }
        }
      } else {
        return {total: (total + Number(packingCharges)).toFixed(2), freight: "0.00"}
      }
    } else {
      // 堂客价格
      return {total: (total).toFixed(2), freight: "0.00"}
    }
  }

  // 新建订单
  const newOrder = (customerId) => {
    let preTime = "";
    const uri = process.env.REACT_APP_API_HOST + "/api/order/new";
    if (showTimeSelect) {
      preTime = currentDay + "/" + currentMonth + "/" + currentYear + " " + preHour + ":" + preMinute;
    }
    const jsonData = JSON.stringify({
      customerId: customerId,
      table: localStorage.getItem("table").toString(),
      method: payType,
      note: noteValue,
      goods: OrderStore.inCartGoodsItems,
      isDeliver: deliveryType === "Delivery",
      preTime: preTime,
    });

    const headers = {
      headers: {
        'Content-Type': 'application/json',
      }
    }

    axios.post(uri, jsonData, headers).then(
      (rsp) => {
        if (rsp.data.status === "success") {
          Toast.show({
            content: "Pedido enviado con éxito."
          })
          OrderStore.clearInCartGoodsItems();
        }
        if (rsp.data.status === "error") {
          Toast.show({
            content: "Error en el envío del pedido."
          })
        }
      }
    ).catch(error => console.log(error))
  }

  return (
    <>
      <div style={{height: windowHeight - 70, padding: '20px 20px 0 20px'}}>
        {/*页头*/}
        <div style={{width: windowWidth - 40}}>
          <div style={{fontSize: '26px', fontWeight: 'bold'}}>Artículos seleccionados</div>
          {localStorage.getItem("table") === "0" ?
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <div style={{margin: '7px 0 10px 0'}} className="f16">Delivery</div>
              <Ask onClick={() => setShowHint(true)}/>
            </div>
            :
            <div style={{margin: '7px 0 10px 0'}} className="f16">Mesa {localStorage.getItem("table")}</div>
          }
        </div>
        {/*商品列表*/}
        <div>
          {OrderStore.inCartGoodsItems.length > 0 ?
            <>
              {/*列表*/}
              <div style={{height: listContainerHeight, overflow: 'auto'}}>
                {OrderStore.inCartGoodsItems.map((goods, index) => {
                  return (
                    <Swipe
                      key={index}
                      rightAction={
                        <div style={{display: 'flex', height: '100%'}}>
                          <Button
                            color="var(--nutui-gray-6)"
                            icon={<IconFont fontClassName="iconfont" classPrefix='icon' name="trash-outline"
                                            size="20px"/>}
                            onClick={() => {
                              OrderStore.deleteInCartGoodsItems(goods.goods.id)
                            }}
                          />
                          <div style={{width: '1px', height: '20px'}}></div>
                        </div>
                      }
                    >
                      <div className="tab_pane_goods_container">
                        <Image
                          src={process.env.REACT_APP_API_HOST + "/uploads/" + goods.goods.image.id + "." + goods.goods.image.ex}
                          width="68px"
                          height="68px"
                          radius="15px"
                        />
                        <div className="tab_pane_goods_text_container">
                          <div className="f14">{goods.goods.name}</div>
                          <div className="tab_pane_goods_text_sub_container">
                            <div>
                              <div className="f12" style={{margin: "3px 0"}}>{goods.goods.chineseName}</div>
                              <div className="f12"
                                   style={{color: 'var(--nutui-color-primary)'}}>€{goods.goods.price.toFixed(2)}</div>
                            </div>
                            <InputNumber
                              readOnly={true}
                              max={99}
                              min={0}
                              value={OrderStore.inCartGoodsItems.length > 0 ? getGoodsNumInCart(goods.goods.id) : 0}
                              onChange={(param) => {
                                OrderStore.setInCartGoodsItems(goods.goods, param)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Swipe>
                  )
                })}
              </div>
              {/*表单*/}
              <div style={{marginTop: '5px'}}>
                {/*支付类型*/}
                <div style={{display: "flex", alignItems: 'center', gap: '20px'}}>
                  <div className="margin_bottom_6">
                    <Radio.Group
                      value={payType}
                      direction="horizontal"
                      onChange={(value) => {
                        setPayType(value)
                      }}
                    >
                      <Radio
                        icon={<IconFont fontClassName="iconfont" classPrefix='icon' name="euro-outline" size="19px"/>}
                        activeIcon={<IconFont fontClassName="iconfont" classPrefix='icon' name="euro-outline"
                                              size="19px" color="var(--nutui-color-primary)"/>}
                        value="Efectivo"
                      >Efectivo</Radio>
                      <Radio
                        icon={<IconFont fontClassName="iconfont" classPrefix='icon' name="credit-outline"
                                        size="20px"/>}
                        activeIcon={<IconFont fontClassName="iconfont" classPrefix='icon' name="credit-outline"
                                              size="20px" color="var(--nutui-color-primary)"/>}
                        value="Tarjeta"
                      >Tarjeta</Radio>
                    </Radio.Group>
                  </div>
                  {localStorage.getItem("table") === '0' && <div className="margin_bottom_6">
                    <Radio.Group
                      value={deliveryType}
                      direction="horizontal"
                      onChange={(value) => {
                        setDeliveryType(value)
                      }}
                    >
                      <Radio
                        icon={<IconFont fontClassName="iconfont" classPrefix='icon' name="truck-outline"
                                        size="20px"/>}
                        activeIcon={<IconFont fontClassName="iconfont" classPrefix='icon' name="truck-outline"
                                              size="20px" color="var(--nutui-color-primary)"/>}
                        value="Delivery"
                      >Delivery</Radio>
                      <Radio
                        icon={<IconFont fontClassName="iconfont" classPrefix='icon' name="bag-outline" size="18px"/>}
                        activeIcon={<IconFont fontClassName="iconfont" classPrefix='icon' name="bag-outline"
                                              size="18px"
                                              color="var(--nutui-color-primary)"/>}
                        value="Recogida"
                      >Recogida</Radio>
                    </Radio.Group>
                  </div>}
                </div>
                {/*预定*/}
                {localStorage.getItem("table") === '0' && <div className="time_select_container">
                  <IconFont fontClassName="iconfont"
                            classPrefix='icon'
                            name="clock-outline"
                            size="19px"
                            color={showTimeSelect ? "var(--nutui-color-primary)" : "var(--nutui-color-text-disabled)"}
                  />
                  <div className="f14">
                    {
                      showTimeSelect ?
                        <div style={{display: "flex", alignItems: 'center'}}>
                          <Input placeholder="Día" defaultValue={currentDay} type="number" maxLength="2"
                                 className="time_input" align="center" onChange={(v) => {
                            setCurrentDay(v)
                          }}/>
                          /{currentMonth}/{currentYear}
                          <Input placeholder="Hora" type="number" maxLength="2" className="time_input"
                                 align="center" onChange={(v) => {
                            setPreHour(v)
                          }}/>
                          <div>:</div>
                          <Input placeholder="Minuto" type="number" maxLength="2" className="time_input"
                                 align="center" onChange={(v) => {
                            setPreMinute(v)
                          }}/>
                          <Close
                            onClick={() => {
                              setShowTimeSelect(false)
                            }}
                            style={{marginLeft: "15px"}}
                          />
                        </div> :
                        <div
                          onClick={
                            () => {
                              setShowTimeSelect(true)
                            }
                          }
                          style={{height: '24px', lineHeight: '24px'}}
                        >
                          Haz clic para seleccionar la hora de reserva
                        </div>
                    }
                  </div>
                </div>}
                {/*备注*/}
                <div className="margin_bottom_6">
                  <TextArea
                    placeholder="Nota..."
                    value={noteValue}
                    onChange={(value) => {
                      setNoteValue(value)
                    }}
                  />
                </div>
                {/*运费及地址*/}
                {localStorage.getItem("table") === '0' &&
                  (
                    UserStore.isLogin ?
                      (
                        isCompleteInfo ? (
                            deliveryType === "Delivery" ?
                              <>
                                <div style={{display: 'flex', marginBottom: '7px'}}>
                                  <IconFont
                                    fontClassName="iconfont"
                                    classPrefix='icon'
                                    name="location-fill"
                                    size="16px"
                                    color="var(--nutui-color-primary)"
                                  />
                                  <div className="overlength_text f14">{UserStore.userInfo.address}</div>
                                </div>
                                <div className="f14 margin_bottom_6" style={{marginLeft: "2px"}}>Gastos de envío:
                                  <span
                                    style={{color: "var(--nutui-color-primary)"}}> €{''}{getTotalPrice().freight}</span>
                                </div>
                                <div className="f14 margin_bottom_6" style={{marginLeft: "2px"}}>Costo de embalado:
                                  <span
                                    style={{color: "var(--nutui-color-primary)"}}> €{''}{packingCharges}</span>
                                </div>
                              </>
                              :
                              <>
                                <div style={{display: 'flex', marginBottom: '7px'}}>
                                  <IconFont
                                    fontClassName="iconfont"
                                    classPrefix='icon'
                                    name="location-fill"
                                    size="16px"
                                    color="var(--nutui-color-primary)"
                                  />
                                  <div className="overlength_text f14">Camino del Cementerio, 19, Valladolid, España</div>
                                </div>
                                <div className="f14 margin_bottom_6" style={{marginLeft: "2px"}}>Gastos de envío:
                                  <span style={{color: "var(--nutui-color-primary)"}}> €{''}{0.00.toFixed(2)}</span>
                                </div>
                                <div className="f14 margin_bottom_6" style={{marginLeft: "2px"}}>Costo de embalado:
                                  <span
                                    style={{color: "var(--nutui-color-primary)"}}> €{''}{packingCharges}</span>
                                </div>
                              </>
                          )
                          :
                          <div className="cart_hint_container">
                            <Warning/>
                            <div style={{marginLeft: '5px'}}>Por favor, complete la información de la cosecha.</div>
                          </div>
                      )
                      :
                      <div className="cart_hint_container">
                        <Warning/>
                        <div style={{marginLeft: '5px'}}>Por favor, inicie sesión para realizar el pedido.</div>
                      </div>
                  )
                }
                {/*合计*/}
                <div className="f14 margin_bottom_6" style={{marginLeft: "2px"}}>Total del pedido:
                  <span style={{color: "var(--nutui-color-primary)"}}> €{''}
                    {getTotalPrice().total}
                  </span>
                </div>
                {/*按钮*/}
                <div style={{display: 'flex'}}>
                  <Button type="primary" style={{flex: "1"}} onClick={() => {
                    const table = localStorage.getItem("table").toString()
                    if (table === "0") {
                      if (UserStore.isLogin) {
                        if (isCompleteInfo) {
                          if (showTimeSelect) {
                            newOrder(UserStore.userInfo.id)
                          } else {
                            if (isOpen()) {
                              newOrder(UserStore.userInfo.id)
                            } else {
                              Toast.show({
                                content: 'Fuera del horario laboral.'
                              })
                              setShowHint(true)
                            }
                          }
                        } else {
                          Toast.show({
                            content: 'Por favor, complete la información.'
                          })
                        }
                      } else {
                        Toast.show({
                          content: 'Por favor, inicie sesión para realizar el pedido.'
                        })
                      }
                    } else {
                      if (UserStore.isLogin) {
                        newOrder(UserStore.userInfo.id)
                      } else {
                        newOrder("00000000-0000-0000-0000-000000000000")
                      }
                    }
                  }}>Enviar</Button>
                  <Button color="var(--nutui-gray-6)" onClick={() => {
                    OrderStore.clearInCartGoodsItems()
                  }}>Vaciar</Button>
                </div>
              </div>
            </>
            :
            <div className="no_data" style={{width: windowWidth - 40, height: windowHeight - 143}}>
              No hay datos...
            </div>
          }
        </div>
      </div>
      <Popup
        visible={showHint}
        position="bottom"
        onClose={() => setShowHint(false)}
      >
        <div style={{padding: '40px'}}>
          <div><b>Nuestro horario de atención es:</b></div>
          <div style={{marginBottom: '20px', padding: '5px', marginTop: '10px'}}>
            <div style={{marginBottom: '5px'}}>12:00 - 16:30 y 19:30 - 23:30</div>
            <div>Cerrado los martes.</div>
          </div>
          <div><b>Tarifas de entrega:</b></div>
          <div style={{padding: '5px', marginTop: '10px'}}>
            <div style={{marginBottom: '10px'}}>Dentro de 3 km, si la compra supera los 15 euros, no se cobrará costo
              de
              entrega; de lo contrario, se
              añadirá un cargo de 2,99 euros.
            </div>
            <div style={{marginBottom: '10px'}}>Entre 3 y 5 km, si la compra supera los 30 euros, no se cobrará costo
              de
              entrega; de lo contrario, se
              añadirá un cargo de 2,99 euros.
            </div>
            <div style={{marginBottom: '10px'}}>Más de 5 km, se añadirá un cargo de 5 euros por la entrega.</div>
            <div>Solo ofrecemos entrega dentro de un radio de 15 kilómetros de la tienda.</div>
          </div>
        </div>
      </Popup>
    </>
  )
}

export default observer(Cart);
