import {observer} from "mobx-react-lite";
import {useEffect, useRef, useState} from "react";
import {Avatar, Button, Input, Popup, Space, Tabs, Toast, VirtualList} from "@nutui/nutui-react";
import {ArrowLeft, IconFont} from "@nutui/icons-react";
import axios from "axios";
import UserStore from "../stores/UserStore";

function Personal() {

  const [page, setPage] = useState(1); // 当前页数
  const isLoading = useRef(false)

  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  const [showLogin, setShowLogin] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [isForget, setIsForget] = useState(false);

  const [titleValue, setTitleValue] = useState("Órdenes históricas");

  const [orders, setOrders] = useState([])

  const [addressSuggestion, setAddressSuggestion] = useState([]);
  const [showAddressSuggestion, setShowAddressSuggestion] = useState(false);

  //登录注册
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  //收货信息
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [addressId, setAddressId] = useState("");
  const [home, setHome] = useState("");
  //倒计时状态
  const [seconds, setSeconds] = useState(60)
  const [countDown, setCountDown] = useState(false)

  //倒计时60s
  useEffect(() => {
    let interval = null;
    if (countDown && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds > 0) {
            return prevSeconds - 1
          }
          if (prevSeconds === 0) {
            clearInterval(interval)
            setCountDown(false)
            return 60
          }
        });
      }, 1000);
    }
  }, [countDown])

  const checkEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  useEffect(() => {
    if (UserStore.isLogin) {
      getHistoryOrder(1)
    }
  }, [])

  //发送验证码
  const sendEmail = () => {
    if (!checkEmail(email)) {
      Toast.show({
        content: "Correo electrónico incorrecto."
      })
      return;
    }
    const uri = process.env.REACT_APP_API_HOST + "/api/user/send/code/" + email;
    axios.get(uri).then(
      (rsp) => {
        Toast.show({
          content: rsp.data.message
        })
      }
    ).catch((error) => {
      console.log(error)
    })
  }
  //复用逻辑
  const reuseLogic = (uri, jsonData) => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
      }
    }
    axios.post(uri, jsonData, headers).then(
      (rsp) => {
        if (rsp.data.status === "success") {
          UserStore.login(rsp.data.data)
          getHistoryOrder(1)
          Toast.show({
            content: rsp.data.message
          })
          setShowLogin(false)
        } else {
          Toast.show({
            content: rsp.data.message
          })
        }
      }
    ).catch((error) => {
      console.log(error)
    })
  }
  //注册
  const register = () => {
    const jsonData = JSON.stringify({email: email, password: password, code: code});
    const uri = process.env.REACT_APP_API_HOST + "/api/user/register";
    reuseLogic(uri, jsonData)
  }
  //重置密码
  const reset = () => {
    const jsonData = JSON.stringify({email: email, password: password, code: code});
    const uri = process.env.REACT_APP_API_HOST + "/api/user/reset";
    reuseLogic(uri, jsonData)
  }
  //登录
  const login = () => {
    if (!checkEmail(email)) {
      Toast.show({
        content: "Correo electrónico incorrecto."
      })
      return;
    }
    const uri = process.env.REACT_APP_API_HOST + "/api/user/login";
    const jsonData = JSON.stringify({email: email, password: password});
    reuseLogic(uri, jsonData)
  }
  //登出
  const logout = () => {
    localStorage.removeItem("token")
    UserStore.isLogin = false
    UserStore.userInfo = {}
    setOrders([])
    setTitleValue("Órdenes históricas")
  }
  //获取地址建议
  const getAddressSuggestions = (e) => {
    setAddress(e)
    if (e.length > 0) {
      const uri = process.env.REACT_APP_API_HOST + "/api/user/address/suggest/get/" + encodeURIComponent(e);
      axios.get(uri).then((rsp) => {
        setShowAddressSuggestion(true);
        setAddressSuggestion(rsp.data.predictions)
      })
    }
  }
  //更新个人信息
  const updateInfo = () => {
    const phoneNumberRegex = /^6[0-9]{8}$|^7[0-9]{8}$/;
    if (!phoneNumberRegex.test(phone.trim())) {
      Toast.show({
        content: "Número de teléfono incorrecto."
      })
      return;
    }
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token")
      }
    }
    const uri = process.env.REACT_APP_API_HOST + "/api/user/update/info";
    const jsonData = JSON.stringify({
      name: name.trim(),
      phone: phone.trim(),
      address: address,
      addressLocation: addressId,
      home: home.trim(),
    });
    axios.post(uri, jsonData, headers).then(
      (rsp) => {
        if (rsp.data.status === "success") {
          Toast.show({
            content: rsp.data.message
          })
          UserStore.setUserInfo()
          setShowEdit(false)
        }
        if (rsp.data.status === "error") {
          Toast.show({
            content: rsp.data.message
          })
        }
      }
    ).catch((error) => {
      console.log(error)
    })
  }
  //获取历史定单
  const getHistoryOrder = (page) => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("token")
      }
    }
    const uri = process.env.REACT_APP_API_HOST + "/api/order/list/all/paid/" + page + "/10";
    axios.get(uri, headers).then(
      (rsp) => {
        if (rsp.data.status === "success") {
          setOrders((prevOrders) => [...prevOrders, ...rsp.data.data.list]);
          isLoading.current = !rsp.data.data.hasNextPage;
        }
        if (rsp.data.status === "error") {
          Toast.show({
            content: rsp.data.message
          })
        }
      }
    ).catch((error) => {
      console.log(error)
    })
  }
  // 根据id获取goods
  const getGoodsById = (id) => {
    const uri = process.env.REACT_APP_API_HOST + "/api/goods/list/all?id=" + id;
    axios.get(uri).then(rsp => {
      if (rsp.data.status === "success") {
        if (rsp.data.data.length > 0) {
          return rsp.data.data[0];
        }
      }
      if (rsp.data.status === "error") {
        Toast.show({
          content: rsp.data.message
        })
      }
    }).catch(error => console.log(error));
  }

  const itemVariable = (order) => {
    return (
      <>
        <div key={order.id} style={{fontFamily: 'Helvetica'}}>
          {/*主内容*/}
          <div style={{marginBottom: '10px'}}>
            {order.goodsRsp.map((good, index) => (
              <div key={index}>
                <div className="flex_between">
                  <div className="f14">{good.name}</div>
                  <div className="dotted_line"></div>
                  <Space>
                    <div className="f12">€{good.price}</div>
                    <div className="f12">x{good.num}</div>
                  </Space>
                </div>
                <div className="f12" style={{color: "var(--nutui-gray-5)"}}>{good.chineseName}</div>
              </div>
            ))}
          </div>
          <div
            className="f12 margin_bottom_6">{order.preTime.length > 0 && "Tiempo reservado: " + order.preTime}</div>
          {/*备注行*/}
          <div className="f12 margin_bottom_6">{order.note.length > 0 && "Nota: " + order.note}</div>
          {/*最后一行*/}
          <Space>
            <div className="f12 margin_bottom_6">{order.method}</div>
            <div className="f12">{order.isDeliver ? "Delivery" : "Recogida"}</div>
          </Space>
          <div className="flex_between margin_bottom_6">
            <div className="f12">{order.status}</div>
            <Space>
              <div className="f12">{order.time}</div>
              <div className="f12">€{(order.totalPrice).toFixed(2)}</div>
            </Space>
          </div>
          {/*<Button size="mini"*/}
          {/*        onClick={() => {*/}
          {/*          console.log(JSON.stringify(order));*/}
          {/*          OrderStore.clearInCartGoodsItems();*/}
          {/*          // OrderStore.setInCartGoodsItems(showGoodsDetailValue, param)*/}
          {/*        }}*/}
          {/*        type="primary"*/}
          {/*>Quiero otra orden</Button>*/}
        </div>
      </>
    )
  }

  const onScroll = () => {
    if (isLoading.current) return
    isLoading.current = true
    getHistoryOrder(page + 1)
    setPage(page + 1)
  }

  return (
    <>
      <div style={{height: windowHeight - 70, padding: '20px 20px 0 20px'}}>
        {/*页头*/}
        <div style={{width: windowWidth - 40}}>
          <div style={{fontSize: '26px', fontWeight: 'bold'}}>Hola, cliente</div>
          <div className="avatar_container">
            {/*头像*/}
            <Avatar
              size="large"
              icon={<IconFont fontClassName="iconfont" classPrefix='icon' name="user-fill" size="45px"/>}
              shape="round"
              onClick={() => {
                if (!UserStore.isLogin) {
                  setShowLogin(true);
                }
              }}
            />
            {/*中间文字*/}
            {
              UserStore.isLogin ?
                <div className="avatar_text_container">
                  {UserStore.userInfo.name ?
                    <div className="f16">Hola, {UserStore.userInfo.name}</div> :
                    UserStore.userInfo.email &&
                    <div className="f16">Hola, {UserStore.userInfo.email.split('@')[0]}</div>
                  }
                  <div className="f14">Miembro</div>
                </div>
                :
                <div className="avatar_text_container" onClick={() => setShowLogin(true)}>
                  <div className="f16">Iniciar sesión/Registrarse</div>
                  <div className="f14">Visitante</div>
                </div>
            }
            {/*左边按钮*/}
            <div style={{marginRight: '10px', position: 'relative', top: '1px'}} onClick={() => {
              setTitleValue("Órdenes históricas")
            }}>
              {titleValue === "Órdenes históricas" ?
                <IconFont fontClassName="iconfont" classPrefix='icon' name="receipt-fill" size="20px"
                          color="var(--nutui-color-primary)"/> :
                <IconFont fontClassName="iconfont" classPrefix='icon' name="receipt-outline" size="20px"/>
              }
            </div>
            <div onClick={() => {
              if (UserStore.isLogin) {
                setTitleValue("Información de envío")
              }
            }}>
              {titleValue === "Información de envío" ?
                <IconFont fontClassName="iconfont" classPrefix='icon' name="setting-fill" size="20px"
                          color="var(--nutui-color-primary)"/> :
                <IconFont fontClassName="iconfont" classPrefix='icon' name="setting-outline" size="20px"/>
              }
            </div>
          </div>
        </div>
        <div className="f16 person_title_container">
              <span>{titleValue}
          </span>
        </div>
        {/*内容*/}
        {UserStore.isLogin ? (
            titleValue === "Información de envío" ?
              <div style={{width: windowWidth - 40, height: windowHeight - 247}}>
                <div className="person_information_container">
                  <div className="person_information_item" style={{justifyContent: 'space-between'}}>
                    <div className="person_information_item" style={{marginBottom: '10px'}}>
                      <IconFont fontClassName="iconfont" classPrefix='icon' name="user-outline" size="18px"/>
                      <div className="person_information_text">{UserStore.userInfo.name}</div>
                    </div>
                    <div>
                      {showEdit ?
                        <IconFont fontClassName="iconfont" classPrefix='icon' name="edit-fill" size="18px"
                                  color="var(--nutui-color-primary)"/> :
                        <IconFont fontClassName="iconfont" classPrefix='icon' name="edit-outline" size="18px"
                                  onClick={() => {
                                    setShowEdit(true);
                                    setName(UserStore.userInfo.name);
                                    setPhone(UserStore.userInfo.phone);
                                    setAddress(UserStore.userInfo.address);
                                    setHome(UserStore.userInfo.home);
                                    setAddressId(UserStore.userInfo.addressLocation);
                                  }}/>}
                    </div>
                  </div>
                  <div className="person_information_item" style={{marginBottom: '10px'}}>
                    <IconFont fontClassName="iconfont" classPrefix='icon' name="phone-outline" size="18px"/>
                    <div className="person_information_text">{UserStore.userInfo.phone}</div>
                  </div>
                  <div className="person_information_item">
                    <IconFont fontClassName="iconfont" classPrefix='icon' name="location-outline" size="18px"/>
                    <div className="person_information_text">{UserStore.userInfo.address}</div>
                  </div>
                  <div className="person_information_item">
                    <IconFont fontClassName="iconfont" classPrefix='icon' name="home-outline" size="18px"/>
                    <div className="person_information_text">{UserStore.userInfo.home}</div>
                  </div>
                  <Button block type="primary" onClick={logout} style={{marginTop: '30px'}}>Cerrar sesión</Button>
                </div>
              </div> :
              <VirtualList
                list={orders}
                itemRender={itemVariable}
                containerHeight={windowHeight - 247}
                itemEqual={false}
                onScroll={onScroll}
              />
          )
          :
          <div className="no_data" style={{width: windowWidth - 40, height: windowHeight - 247}}>
            No hay datos...
          </div>
        }
      </div>
      {/*登录/注册*/}
      <Popup
        visible={showLogin}
        position="bottom"
        onClose={() => setShowLogin(false)}
      >
        <div className="person_popup_container">
          {isForget ?
            <>
              {/*忘记密码*/}
              <div className="person_popup_inner_container">
                <div className="person_forget_title_container" onClick={() => setIsForget(false)}>
                  <ArrowLeft width="15px" height="15px"/>
                  <div style={{marginLeft: '14px'}} className="f16">Restablecer la contraseña e iniciar sesión</div>
                </div>
                <div className="person_popup_input_container">
                  <IconFont fontClassName="iconfont" classPrefix='icon' name="email-outline" size="20px"/>
                  <Input placeholder="email" value={email} onChange={(value) => setEmail(value)}/>
                </div>
                <div className="person_popup_input_container">
                  <IconFont fontClassName="iconfont" classPrefix='icon' name="lock-outline" size="20px"/>
                  <Input placeholder="nueva contraseña" value={password} onChange={(value) => setPassword(value)}/>
                </div>
                <div className="person_popup_input_container">
                  <IconFont fontClassName="iconfont" classPrefix='icon' name="key-outline" size="20px"/>
                  <Input placeholder="código" value={code} onChange={(value) => setCode(value)}/>
                  <Button color="var(--nutui-color-primary)" size="mini" onClick={() => {
                    if (!countDown) {
                      setCountDown(true)
                      sendEmail()
                    }
                  }}>
                    {countDown ? seconds + " S" : "Obtener código"}
                  </Button>
                </div>
                <Button block color="var(--nutui-color-primary)" onClick={reset}>Iniciar sesión</Button>
              </div>
            </>
            :
            <Tabs activeType="card">
              {/*登录*/}
              <Tabs.TabPane title="Iniciar sesión">
                <div className="person_popup_inner_container">
                  <div className="person_popup_input_container">
                    <IconFont fontClassName="iconfont" classPrefix='icon' name="email-outline" size="20px"/>
                    <Input placeholder="email" value={email} onChange={(value) => setEmail(value)}/>
                  </div>
                  <div className="person_popup_input_container">
                    <IconFont fontClassName="iconfont" classPrefix='icon' name="lock-outline" size="20px"/>
                    <Input type="password" placeholder="contraseña" value={password}
                           onChange={(value) => setPassword(value)}/>
                  </div>
                  <div
                    className="person_forget_text_container"
                    onClick={() => {
                      setIsForget(true)
                    }}>Recupera tu contraseña
                  </div>
                  <Button block color="var(--nutui-color-primary)" onClick={login}>Iniciar sesión</Button>
                </div>
              </Tabs.TabPane>
              {/*注册*/}
              <Tabs.TabPane title="Registrarse">
                <div className="person_popup_inner_container">
                  <div className="person_popup_input_container">
                    <IconFont fontClassName="iconfont" classPrefix='icon' name="email-outline" size="20px"/>
                    <Input placeholder="email" value={email} onChange={(value) => setEmail(value)}/>
                  </div>
                  <div className="person_popup_input_container">
                    <IconFont fontClassName="iconfont" classPrefix='icon' name="lock-outline" size="20px"/>
                    <Input placeholder="contraseña" value={password} onChange={(value) => setPassword(value)}/>
                  </div>
                  <div className="person_popup_input_container">
                    <IconFont fontClassName="iconfont" classPrefix='icon' name="key-outline" size="20px"/>
                    <Input placeholder="código" value={code} onChange={(value) => setCode(value)}/>
                    <Button color="var(--nutui-color-primary)" size="mini" onClick={() => {
                      if (!countDown) {
                        setCountDown(true)
                        sendEmail()
                      }
                    }}>
                      {countDown ? seconds + " S" : "Obtener código"}
                    </Button>
                  </div>
                  <Button block color="var(--nutui-color-primary)" onClick={register}>Registrarse</Button>
                </div>
              </Tabs.TabPane>
            </Tabs>
          }
        </div>
      </Popup>

      {/*个人信息编辑*/}
      <Popup
        visible={showEdit}
        position="bottom"
        onClose={() => setShowEdit(false)}
      >
        <div className="person_edit_popup_container">
          <>
            <div className="person_forget_text_container f16">Editar información personal</div>
            <div>
              <div className="person_popup_input_container">
                <IconFont fontClassName="iconfont" classPrefix='icon' name="user-outline" size="20px"/>
                <Input placeholder="nombre" onChange={(value) => setName(value)} value={name}/>
              </div>
              <div className="person_popup_input_container">
                <IconFont fontClassName="iconfont" classPrefix='icon' name="phone-outline" size="20px"/>
                <Input placeholder="teléfono" onChange={(value) => setPhone(value)} value={phone}/>
              </div>
              <div className="person_popup_input_container">
                <IconFont fontClassName="iconfont" classPrefix='icon' name="home-outline" size="20px"/>
                <Input placeholder="Ejemplo: Piso 3, Puerta B" onChange={(value) => setHome(value)} value={home}/>
              </div>
              <div className="person_popup_input_container" style={{position: 'relative'}}>
                <IconFont fontClassName="iconfont" classPrefix='icon' name="location-outline" size="20px"/>
                <Input
                  placeholder="drección"
                  onChange={getAddressSuggestions}
                  value={address}
                  onClick={() => {
                    if (showAddressSuggestion) {
                      setShowAddressSuggestion(false);
                    }
                  }}
                />
                <div className="person_edit_popup_address_container"
                     style={{display: showAddressSuggestion && addressSuggestion.length > 0 ? 'block' : 'none'}}>
                  {addressSuggestion.map((address, index) => (
                    <div
                      key={index}
                      className="address_item_container"
                      onClick={() => {
                        setAddress(address.description)
                        setAddressId(address.place_id)
                        setShowAddressSuggestion(false)
                      }}
                    >
                      {address.description}
                    </div>
                  ))}
                </div>
              </div>
              <Button
                block
                color="var(--nutui-color-primary)"
                style={{marginBottom: '148px'}}
                onClick={updateInfo}
              >Guardar</Button>
            </div>
          </>
        </div>
      </Popup>
    </>
  )
}

export default observer(Personal);
