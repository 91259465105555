import {IconFont} from "@nutui/icons-react";
import {Tabbar} from "@nutui/nutui-react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";

function Main() {
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  const navigate = useNavigate();
  const location = useLocation()

  const [selectedTab, setSelectedTab] = useState(0)

  const bottomNavigateItems = [
    {
      id: 0,
      key: '/menu',
      name: 'Menú',
      icon: <IconFont fontClassName="iconfont" classPrefix='icon' name="squares-outline" size="24px"/>,
      iconFill: <IconFont fontClassName="iconfont" classPrefix='icon' name="squares-fill" size="24px"/>,
    },
    {
      id: 1,
      key: '/cart',
      name: 'Carrito',
      icon: <IconFont fontClassName="iconfont" classPrefix='icon' name="shopping-cart-outline" size="24px"/>,
      iconFill: <IconFont fontClassName="iconfont" classPrefix='icon' name="shopping-cart-fill" size="24px"/>,
    },
    {
      id: 2,
      key: '/order',
      name: 'Pedido',
      icon: <IconFont fontClassName="iconfont" classPrefix='icon' name="receipt-outline" size="24px"/>,
      iconFill: <IconFont fontClassName="iconfont" classPrefix='icon' name="receipt-fill" size="24px"/>,
    },
    {
      id: 3,
      key: '/personal',
      name: 'Perfil',
      icon: <IconFont fontClassName="iconfont" classPrefix='icon' name="user-outline" size="24px"/>,
      iconFill: <IconFont fontClassName="iconfont" classPrefix='icon' name="user-fill" size="24px"/>,
    }
  ]

  useEffect(() => {
    if (location.pathname === '/') {
      const query = new URLSearchParams(location.search);
      const tableNumber = query.get("table");
      if (tableNumber) {
        localStorage.setItem("table", tableNumber);
      } else {
        localStorage.setItem("table", "0");
      }
      navigate("/menu");
    }

    bottomNavigateItems.forEach((item) => {
      if (item.key === location.pathname) {
        setSelectedTab(item.id)
      }
    })
  }, [location]);

  return (
    <div style={{
      height: windowHeight,
      width: windowWidth,
      backgroundImage: 'radial-gradient(circle 700px at 140% -20%, var(--nutui-color-primary), #f9f7fb)'
    }}>
      <Outlet/>
      <Tabbar
        value={selectedTab}
        fixed
        onSwitch={
          (value) => {
            navigate(bottomNavigateItems[value].key)
            setSelectedTab(value)
          }}
      >
        {bottomNavigateItems.map(item => (
          <Tabbar.Item key={item.key} title={item.name} icon={selectedTab === item.id ? item.iconFill : item.icon}/>
        ))}
      </Tabbar>
    </div>
  )
}

export default Main;
